@import "base/modernize-vars";

header#main-nav nav#nav-sidebar {
  z-index: 9999;
  font-family: $font-family;

  #sidebar-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11px 16px;
    background-color: $grey-light;

    .logo-close-button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &.add-vertical-spacing {
        margin-bottom: 16px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .hamburger-menu-close-button {
    background-color: transparent;
    border: none;
    color: $blue-gray-600;
    font-size: 24px;
    padding: 0;
    margin: 0;
  }

  #sidebar-content {
    background-color: white;
    width: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;

    #sidebar-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 8px 0;
    }

    #user-profile {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        font-size: 12px;
      }

      #user-label {
        font-size: 16px;
      }

      #user-display {
        display: flex;
        align-items: center;
        font-size: 12px;

        .user-bubble,
        .user-avatar {
          margin-right: 16px;
        }
      }
    }

    ul.nav-actions {
      flex-direction: column;
      color: $font-black;
      align-items: unset;
      gap: 0;

      #upload {
        margin: 0;
      }

      li {
        margin: 0 16px;
        padding: 12px 0;

        a {
          line-height: 21px;
          color: #303133;
          padding: 0;
        }
        a#upload {
          color: #fff;
        }
      }

      li.overlined {
        border-top: 1px solid $blue-gray-300;
      }
    }

    li {
      margin: 0;
    }

    ul.user-actions {
      padding: 0 16px;

      a {
        font-weight: 500;
      }

      .user-actions-link-container {
        padding: 12px 16px;
      }

      .user-actions-link {
          color: #303133;

        &:hover {
          color: $blue-dark;
        }
      }
    }
  }
}

header#main-nav nav#nav-sidebar.open {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 0;

  #sidebar-content {
    overflow-y: auto;
  }
}

@media screen and (max-width: 300px) {
  header#main-nav nav#nav-sidebar {
    #sidebar-content {
      width: 200px;
    }
  }
}
